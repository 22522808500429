import { createSelector, createSlice } from "@reduxjs/toolkit";

const matchNameSlice = createSlice({
  name: "matchnameref",
  initialState: {
    matchName: null,
    matchType: null,
  },
  reducers: {
    setMatchName: (state, action) => {
      const { matchName, matchType } = action.payload;
      state.matchName = matchName;
      state.matchType = matchType;
    },
  },
});
export default matchNameSlice.reducer;

export const { setMatchName } = matchNameSlice.actions;

export const elementSelector = createSelector(
  (state) => state.matchnameref,
  (state) => state
);
