import ReactDOM from "react-dom/client";
import "./index.scss";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import React, { Suspense } from "react";
// import { BounceLoader } from "react-spinners";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/Snackbar.tsx";

const App = React.lazy(() => import("./App.tsx"));

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    {/* <Suspense fallback={<BounceLoader color="#36d7b7" />}> */}
      <SnackbarProvider
        maxSnack={2}
        autoHideDuration={1500}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}>
        <App />
        <SnackbarUtilsConfigurator />
      </SnackbarProvider>
    {/* </Suspense> */}
  </Provider>
);
